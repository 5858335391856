<template>
  <div v-if="paymentMethods">
    <div class="fluid-xs leading-snug uppercase font-medium mb-4">{{ $t('navigation.paymentMethods') }}</div>
    <PaymentMethodsIcons :methods="paymentMethods" />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import PaymentMethodsIcons from '@/components/common/PaymentMethodsIcons';

export const PaymentMethodsFragment = gql`
  fragment PaymentMethodsFragment on paymentMethods_GlobalSet {
    paymentMethods
  }
`;

export default {
  components: {
    PaymentMethodsIcons,
  },
  props: {
    paymentMethods: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
