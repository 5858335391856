<template>
  <div class="fluid-xs leading-tightest border rounded p-4 [&_a]:font-medium [&_a]:underline" :class="colorStyle">
    <p v-html="message"></p>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: 'info',
      validator: (value) => {
        return ['error', 'success', 'info'].includes(value);
      },
    },
  },

  computed: {
    colorStyle() {
      return {
        'text-white bg-br-red border-br-red': this.type === 'error',
        'text-white bg-br-green border-br-green': this.type === 'success',
        'bg-blackly border-white text-white': this.type === 'info',
      };
    },
  },
};
</script>
