import gql from 'graphql-tag';

export const CartShippingEntryFragment = gql`
  fragment CartShippingEntryFragment on cartSettings_GlobalSet {
    shippingEntry {
      ...LinkFragment
    }
  }
`;

export const CartDealsSettingsFragment = gql`
  fragment CartDealsSettingsFragment on cartSettings_GlobalSet {
    showIcon
    useAlternativeStyle
  }
`;

export const DeliveryDetailsSettingsFragment = gql`
  fragment DeliveryDetailsSettingsFragment on cartSettings_GlobalSet {
    deliveryDetails {
      ... on deliveryDetailsBlock_Entry {
        id
        label
        hasTooltip
        tooltipContent
        valueDefault
        valueWithFreeShipping
      }
    }
  }
`;

export const HighDemandProductsSettingsFragment = gql`
  fragment HighDemandProductsSettingsFragment on cartSettings_GlobalSet {
    cartHighDemandMessage
    productHandles {
      ... on productHandlesBlock_Entry {
        productHandle
      }
    }
  }
`;

export const state = () => ({
  paymentMethods: null,
  notificationActive: false,
  notificationFontColor: null,
  notificationBackgroundColor: null,
  notificationHeading: null,
  notificationBody: null,
  shippingEntry: null,
  cartDealSettings: {},
  deliveryDetails: [],
  cartHighDemandMessage: false,
  highDemandProducts: [],
});

export const getters = {
  shippingEntryUri: (state) => state.shippingEntry?.element?.uri || null,
  hasPaypalMethod(state) {
    if (!state.paymentMethods) return false;

    return state.paymentMethods.includes('paypal');
  },
};

export const mutations = {
  INIT(state, payload) {
    const {
      notificationActive,
      notificationFontColor,
      notificationBackgroundColor,
      notificationHeading,
      notificationBody,
      paymentMethods,
      shippingEntry,
      cartHighDemandMessage,
      productHandles,
    } = payload;

    state.notificationActive = notificationActive;
    state.notificationFontColor = notificationFontColor;
    state.notificationBackgroundColor = notificationBackgroundColor;
    state.notificationHeading = notificationHeading;
    state.notificationBody = notificationBody;
    state.paymentMethods = paymentMethods.paymentMethods;
    state.shippingEntry = shippingEntry;
    state.cartHighDemandMessage = cartHighDemandMessage;
    state.highDemandProducts = productHandles.map((product) => product.productHandle);
  },

  INIT_CART_DEALS(state, payload) {
    state.cartDealSettings = {
      showIcon: payload.showIcon,
      useAlternativeStyle: payload.useAlternativeStyle,
    };

    state.deliveryDetails = payload.deliveryDetails;
  },
};
