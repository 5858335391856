import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import { print } from 'graphql';
import {
  ProductCardFragment,
  ProductCardByHandleQuery,
  ProductCardByVariantIdQuery,
} from '@/components/shop/products/ProductCard';
import { ShopifyComparisonQuery } from '@/components/shop/collections/Collection';
import { ProductByHandleQuery } from '@/pages/products/_slug/index';

export default function (ctx, inject) {
  const {
    app: {
      i18n: { locale, locales },
    },
  } = ctx;
  const { env, $sentry } = ctx;

  let client = null;

  const createClient = (locale) => {
    const { shopifyApiAccessToken, shopifyApiDomain } = locales.find((l) => l.code === locale);

    if (!shopifyApiAccessToken) return Promise.reject(new Error(`shopifyApiAccessToken not set for locale: ${locale}`));
    if (!shopifyApiDomain) return Promise.reject(new Error(`shopifyApiDomain not set for locale: ${locale}`));

    const endPoint = `https://${shopifyApiDomain}/api/${env.shopifyApiVersion}/graphql`;

    client = new GraphQLClient(endPoint, {
      headers: {
        'X-Shopify-Storefront-Access-Token': shopifyApiAccessToken,
        'accept-language': locale,
        Accept: 'application/json',
      },
    });
  };

  createClient(locale);
  inject('shopifyRefreshClient', createClient);

  const transformLangVariable = function (variables) {
    if (!variables) {
      return {};
    }

    const map = {
      'ch-fr': 'fr',
      'ch-de': 'de',
    };

    if (variables.lang && map[variables.lang]) {
      variables.lang = map[variables.lang];
    }

    if (variables.lang) {
      variables.lang = variables.lang.toUpperCase();
    }

    return variables;
  };

  const request = (query, variables) => {
    const stringyfiedQuery = typeof query === 'object' && query.kind === 'Document' ? print(query) : query;
    return client.request(stringyfiedQuery, transformLangVariable(variables));
  };
  inject('shopifyRequest', request);

  /**
   * query Collection data from Shopify, sanitizes variables
   * @param {Ojbect} variables - handle , limit , cursor
   * @returns {Promise} - shopify Collection data
   */
  const collectionRequest = (rawVariables) => {
    const ShopifyCollectionQuery = gql`
      query ShopifyCollectionQuery($handle: String!, $limit: Int = 20, $cursor: String = null, $lang: LanguageCode!)
      @inContext(language: $lang) {
        collection: collectionByHandle(handle: $handle) {
          handle
          title
          products(first: $limit, after: $cursor) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            edges {
              cursor
              node {
                ...ProductCardFragment
              }
            }
          }
        }
      }

      ${ProductCardFragment}
    `;

    const variables = { ...rawVariables };
    if (rawVariables.limit) variables.limit = parseInt(rawVariables.limit);

    return request(ShopifyCollectionQuery, variables)
      .then((data) => data.collection)
      .catch((error) => {
        $sentry.captureException(error);
        return null;
      });
  };
  inject('shopifyCollectionRequest', collectionRequest);

  /**
   * query Comparison Collection data from Shopify, sanitizes variables
   * @param {Ojbect} variables - handle, limit
   * @returns {Promise} - shopify Comparison Collection data
   */
  const comparisonRequest = (rawVariables) => {
    const variables = { ...rawVariables, lang: locale };
    if (rawVariables.limit) variables.limit = parseInt(rawVariables.limit);

    return request(ShopifyComparisonQuery, variables);
  };
  inject('shopifyComparisonRequest', comparisonRequest);

  /**
   * query multiple Products by handle from shopify
   * capture sentry error for any not found products
   *
   * @param {Array} handles
   * @returns {Promise} - shopify Product Array
   */
  const productsRequest = (handles) => {
    const singleProductRequest = (handle) =>
      request(ProductCardByHandleQuery, { handle, lang: locale })
        .then((data) => {
          if (!data.product) {
            console.warn(`Product Data for handle '${handle}' could not be found!`);
          }

          return data.product;
        })
        .catch((error) => {
          console.warn(`Product Data for handle '${handle}' could not be found!`);
          $sentry.captureException(error);
          return null;
        });
    return Promise.all(handles.map(singleProductRequest));
  };
  inject('shopifyProductsRequest', productsRequest);

  const shopifyProductByHandle = (handle) => {
    return request(ProductByHandleQuery, { handle, lang: locale })
      .then((data) => {
        if (!data.productByHandle) {
          console.warn(`Product Data for handle '${handle}' could not be found!`);
          return null;
        }

        return data.productByHandle;
      })
      .catch((error) => {
        $sentry.captureException(error);
        return null;
      });
  };
  inject('shopifyProductByHandle', shopifyProductByHandle);

  const shopifyProductByVariantId = (id) => {
    return request(ProductCardByVariantIdQuery, { id })
      .then((data) => {
        console.log('variant', data);

        if (!data.product) {
          console.warn(`Product Data for id '${id}' could not be found!`);
          return null;
        }

        return data.product;
      })
      .catch((error) => {
        console.log('error', error);
        $sentry.captureException(error);
        return null;
      });
  };
  inject('shopifyProductById', shopifyProductByVariantId);
}
