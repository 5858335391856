var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col w-full"},[(_vm.showLabel && _vm.label)?_c('label',{staticClass:"fluid-sm leading-tightest mb-2 font-light text-black",attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label)),(_vm.required)?_c('span',{staticClass:"text-br-red"},[_vm._v("*")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"select-wrapper items-center h-9 relative w-full",class:[_vm.disabled ? 'after:bg-transparent' : 'after:bg-black']},[_c('select',_vm._b({staticClass:"fluid-sm leading-tightest focus:outline-none w-full h-full font-medium grid text-black border-b appearance-none px-6",class:[
        { 'text-center': _vm.textCenter },

        !_vm.error ? 'border-gray-200 focus:border-br-green' : 'border-br-red',
        {
          'bg-white': _vm.appearance === 'light',
          'bg-lighter-gray': _vm.appearance === 'default',
        },
      ],attrs:{"id":_vm.id,"aria-invalid":_vm.error ? true : null,"aria-describedby":_vm.error ? `${_vm.id}-error` : null,"disabled":_vm.disabled},on:{"input":(e) => {
          _vm.$emit('input', e.target.value);
        }}},'select',_vm.$attrs,false),[(_vm.showLabel)?_c('option',{attrs:{"value":"","disabled":""},domProps:{"selected":!_vm.value}},[_vm._v(_vm._s(_vm.$t(_vm.label)))]):_vm._e(),_vm._v(" "),_vm._l((_vm.options),function(option,index){return _c('option',{key:`${index}-${_vm.id}-${option.value}`,staticClass:"font-light",attrs:{"disabled":option.disabled},domProps:{"value":option.value,"selected":option.value === _vm.value}},[_vm._v("\n        "+_vm._s(option.label)+"\n      ")])})],2),_vm._v(" "),_c('div',{staticClass:"right-9 absolute top-0 flex flex-row items-center h-full"},[(_vm.error)?_c('WarningIcon',{staticClass:"h-4"}):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"fluid-xs leading-tightest text-medium py-1"},[(_vm.helpText)?_c('p',[_vm._v(_vm._s(_vm.helpText))]):_vm._e(),_vm._v(" "),(_vm.showErrors && _vm.error)?_c('p',{staticClass:"text-br-red",attrs:{"id":`${_vm.id}-error`}},[_vm._v(_vm._s(_vm.error))]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }