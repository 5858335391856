<template>
  <div v-if="cart !== null">
    <div class="gap-y-6 grid">
      <div
        v-if="hasDiscountApplied"
        class="bg-br-green-light fluid-xs leading-tightest py-3 font-medium text-center text-black"
      >
        <TagIcon class="inline-block h-3 mr-1 -mt-px" />
        <span>{{ $t('cart.appliedDiscountHint') }}</span>
        <span v-if="obfuscatedDiscountCode" class="-ml-px before:content-[':'] before:mr-1">{{
          obfuscatedDiscountCode
        }}</span>
      </div>
      <div>
        <div v-if="hasDiscountApplied" class="fluid-base leading-relaxed flex justify-between font-normal">
          <div>{{ $t('cart.discountTotal') }}</div>
          <div v-if="discountValue" class="text-br-green">
            <span class="mr-1">-</span
            >{{
              $n(discountValue.amount, {
                style: 'currency',
                currency: discountValue.currencyCode,
              })
            }}
          </div>
        </div>

        <div class="fluid-base leading-relaxed flex justify-between font-normal">
          <div>{{ $t('cart.subtotal') }}</div>

          <div v-if="isProcessing" class="text-blackly">
            <SpinnerIcon class="h-4 animate-spin" />
          </div>
          <div v-else class="flex flex-col items-end">
            <div>
              {{
                $n(cart.cost.totalAmount.amount, {
                  style: 'currency',
                  currency: cart.cost.totalAmount.currencyCode,
                })
              }}
            </div>
            <s v-if="hasDiscountApplied" class="text-gray-light fluid-xs leading-tightest font-light">
              {{
                $n(totalAmountWithoutDiscount, {
                  style: 'currency',
                  currency: cart.cost.subtotalAmount.currencyCode,
                })
              }}
            </s>
          </div>
        </div>
        <div class="fluid-xs leading-tightest text-gray-light">{{ $t('cart.vatHint') }}</div>
      </div>

      <DeliveryDetails />

      <template v-if="!hasDiscountApplied">
        <hr />
        <div class="fluid-xs leading-tightest">{{ $t('cart.voucherHint') }}</div>
      </template>
      <div>
        <div class="fluid-xs leading-tightest mb-2 font-medium">{{ $t('navigation.paymentMethods') }}</div>
        <PaymentMethodsIcons v-if="paymentMethods" :methods="paymentMethods" />
      </div>
      <CartNotification />
    </div>
  </div>
</template>

<script>
import CartNotification from '@/components/cart/CartNotification';
import { mapActions, mapGetters, mapState } from 'vuex';
import PaymentMethodsIcons from '@/components/common/PaymentMethodsIcons';
import SpinnerIcon from '@/components/icons/SpinnerIcon.vue';
import TagIcon from '@/components/icons/TagIcon';
import DeliveryDetails from '@/components/cart/DeliveryDetails';

export default {
  components: {
    DeliveryDetails,
    PaymentMethodsIcons,
    CartNotification,
    TagIcon,
    SpinnerIcon,
  },
  computed: {
    ...mapGetters('cart', ['hasDiscountApplied', 'discountValue', 'appliedDiscountCode', 'isProcessing']),
    ...mapState('cartSettings', ['paymentMethods']),
    ...mapState('cart', ['cart']),

    priceWithGiftCard() {
      const price = +this.cart.cost.subtotalAmount.amount;
      return price > 0 ? price : 0;
    },

    totalAmountWithoutDiscount() {
      return parseFloat(this.cart.cost.totalAmount.amount) + this.discountValue.amount;
    },

    obfuscatedDiscountCode() {
      if (
        this.hasDiscountApplied &&
        (this.appliedDiscountCode.toLowerCase().startsWith('xsell-deal') ||
          this.appliedDiscountCode.toLowerCase().startsWith('upsell-deal') ||
          this.appliedDiscountCode.toLowerCase().startsWith('test'))
      ) {
        return false;
      }

      return this.appliedDiscountCode;
    },
  },
  methods: {
    ...mapActions('cart', ['close']),
  },
};
</script>
