<template>
  <div :class="{ 'bg-putty py-20': backgroundColorGray, 'my-20': !backgroundColorGray }">
    <div class="relative">
      <div class="container md:hidden">
        <div v-if="smallFeatureTwoProducts" class="relative overflow-hidden" data-test-id="small-feature">
          <CldnImg
            v-if="imageSrc"
            :src="imageSrc"
            :alt="alt"
            sizes="(min-width: 1440px) 420px, (min-width: 768px) 33vw, 100vw"
            class="object-cover h-full w-full"
            crop="fill"
            :ratio="2 / 3"
          />
          <div v-if="featureStyleBar" class="inset-0 absolute">
            <BarText
              class="fluid-3xl leading-tightest font-semibold break-words px-10 py-16 md:py-24"
              :text="featureText"
            />
          </div>
          <div v-else class="absolute inset-x-0 bottom-0 md:bottom-auto md:top-0 md:py-24 md:px-8">
            <div class="bg-blackly text-white py-8 px-8 md:inline-block md:max-w-full">
              <div
                v-if="featureText"
                class="fluid-3xl leading-tightest font-semibold break-words"
                v-html="featureText"
              ></div>
            </div>
          </div>
        </div>
        <div v-if="largeFeatureOneProduct" class="col-span-2 relative" data-test-id="large-feature">
          <FixedAspect :ratio="1">
            <CldnImg
              v-if="imageSrc"
              :src="imageSrc"
              :alt="alt"
              sizes="(min-width: 1440px) 853px, (min-width: 768px) 66vw, 100vw"
              class="object-cover h-full w-full md:position-relative absolute"
              crop="fill"
              :ratio="2 / 3"
            />
          </FixedAspect>
          <BarText
            v-if="featureStyleBar"
            class="fluid-3xl leading-tightest font-semibold bottom-16 px-6 md:px-10 inset-x-0 md:bottom-24 absolute break-words"
            :text="featureText"
          />
          <div v-else class="md:inset-0 md:absolute md:py-24">
            <div class="md:sticky absolute bottom-0 md:bottom-auto md:mx-10 md:top-24 w-full md:w-auto">
              <div class="bg-blackly px-8 py-12 text-white w-full md:w-auto max-w-full md:inline-block">
                <div
                  v-if="featureText"
                  class="fluid-3xl leading-tightest font-semibold break-words"
                  v-html="featureText"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductCardSlider v-bind="sliderProps">
        <div
          v-if="smallFeatureTwoProducts"
          class="relative overflow-hidden hidden md:block"
          data-test-id="small-feature"
        >
          <CldnImg
            v-if="imageSrc"
            :src="imageSrc"
            :alt="alt ? alt : image[0].title"
            :sizes="'(min-width: 1440px) 420px,(min-width: 768px) 33vw, 100vw'"
            class="object-cover h-full w-full"
            crop="fill"
            :ratio="2 / 3"
          />
          <div v-if="featureStyleBar" class="inset-0 absolute">
            <BarText
              class="fluid-3xl leading-tightest font-semibold break-words px-10 py-16 md:py-24"
              :text="featureText"
            />
          </div>
          <div v-else class="absolute inset-x-0 bottom-0 md:bottom-auto md:top-0 md:py-24 md:px-8">
            <div class="bg-blackly text-white py-8 px-8 md:inline-block md:max-w-full">
              <h2 v-if="featureText" class="fluid-3xl leading-tightest break-words" v-html="featureText"></h2>
            </div>
          </div>
        </div>
        <div v-if="largeFeatureOneProduct" class="col-span-2 relative hidden md:block" data-test-id="large-feature">
          <CldnImg
            v-if="imageSrc"
            :src="imageSrc"
            :alt="alt ? alt : image[0].title"
            :sizes="'(min-width: 1440px) 853px,(min-width: 768px)66vw, 100vw'"
            class="object-cover h-full w-full md:position-relative absolute"
            crop="fill"
            :ratio="2 / 3"
          />
          <BarText
            v-if="featureStyleBar"
            class="bottom-16 px-6 md:px-10 inset-x-0 md:bottom-24 absolute break-words fluid-3xl leading-tightest font-semibold"
            :text="featureText"
          />
          <div v-else class="md:inset-0 md:absolute md:py-24">
            <div class="md:sticky absolute bottom-0 md:bottom-auto md:mx-10 md:top-24 w-full md:w-auto">
              <div class="bg-blackly px-8 py-12 text-white w-full md:w-auto max-w-full md:inline-block">
                <div
                  v-if="featureText"
                  class="fluid-3xl leading-tightest font-semibold break-words"
                  v-html="featureText"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </ProductCardSlider>
    </div>
  </div>
</template>

<script>
import ProductCardSlider from '@/components/common/ProductCardSlider';
import CldnImg from '@/components/CldnImg';
import BarText from '@/components/BarText';
import FixedAspect from '@/components/common/FixedAspect';

const PRODUCT_MAX = 9;

export default {
  name: 'CommonProductsSlider',
  components: { FixedAspect, ProductCardSlider, CldnImg, BarText },
  props: {
    layout: {
      type: String,
      default: 'onlyProducts',
      validator: (value) => ['onlyProducts', 'smallFeatureTwoProducts', 'largeFeatureOneProduct'].includes(value),
      required: true,
    },
    heading: {
      type: String,
      default: null,
    },
    backgroundColorGray: {
      type: Boolean,
      default: false,
    },
    image: {
      type: Array,
      default: () => [],
    },
    alt: {
      type: String,
      default: null,
    },
    featureStyle: {
      type: String,
      default: 'barText',
    },
    featureText: {
      type: String,
      default: null,
    },
    collectionOrProducts: {
      type: Array,
      default: () => [],
      required: true,
    },

    blockPosition: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      products: [],
    };
  },
  async fetch() {
    await this.fetchProducts();
  },
  computed: {
    featureStyleBar() {
      return this.featureStyle === 'barText';
    },
    imageSrc() {
      return this.image && this.image[0] ? this.image[0]?.url : null;
    },
    onlyProducts() {
      return this.layout === 'onlyProducts';
    },
    smallFeatureTwoProducts() {
      return this.layout === 'smallFeatureTwoProducts';
    },
    largeFeatureOneProduct() {
      return this.layout === 'largeFeatureOneProduct';
    },
    productHandles() {
      const entries = this.collectionOrProducts?.filter((e) => e.typeHandle === 'products') || [];
      return entries.map((e) => e.shopifyHandle);
    },
    collectionHandle() {
      const collections = this.collectionOrProducts?.filter((e) => e.typeHandle === 'collections') || [];
      return collections && collections.length ? collections[0]?.collectionHandle : null;
    },
    sliderProps() {
      return {
        heading: this.heading,
        products: this.products ? this.products : [],
        blockPosition: this.blockPosition,
      };
    },
  },
  methods: {
    /**
     * fetches products by multiple product-handles or Single Collection
     */
    async fetchProducts() {
      try {
        if (this.productHandles.length > 0) {
          const productRequests = await this.$shopifyProductsRequest(this.productHandles);
          // filter out null-products for not found
          this.products = productRequests.filter(Boolean);
        } else if (this.collectionHandle) {
          const variables = { handle: this.collectionHandle, limit: PRODUCT_MAX, lang: this.$i18n.locale };
          const collection = await this.$shopifyCollectionRequest(variables);
          this.products = collection?.products?.edges.map((edge) => edge.node) || [];
        }
      } catch (error) {
        this.$sentry.captureException(error);
      }
    },
  },
};
</script>
