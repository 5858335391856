<template>
  <transition
    leave-class="scale-y-100 opacity-100"
    leave-to-class="scale-y-0 opacity-0"
    leave-active-class="transition"
  >
    <div
      v-if="marketingUspBlocks && marketingUspBlocks.length"
      class="items-center fluid-xs leading-tightest relative flex justify-center bg-br-green-light text-black origin-top"
    >
      <div class="flex flex-wrap md:flex-nowrap justify-center text-center py-2">
        <div v-for="(block, idx) in marketingUspBlocks" :key="idx" class="flex items-center">
          <img v-if="block.typeHandle === 'icon'" :src="block.svg[0].url" :alt="block.text" class="h-4 mr-2" />
          <div v-if="block.typeHandle === 'text'" class="mx-1">{{ block.text }}</div>
          <Countdown v-if="block.typeHandle === 'countdown'" :date-time="block.dateTime" class="mx-1" />
          <LinkField v-if="block.typeHandle === 'cta'" v-bind="block.cta" class="underline mx-1 font-medium" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex';
import Countdown from '@/components/layout/Countdown';
import LinkField from '@/components/common/LinkField';

export default {
  components: { LinkField, Countdown },

  computed: {
    ...mapState('globalBanner', ['marketingUspBlocks']),
  },
};
</script>
