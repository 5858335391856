<template>
  <div>
    <div v-for="(block, index) in blocks" :key="index">
      <component
        :is="block.typeHandle"
        v-if="isVisible(block)"
        v-bind="{ ...block, socialMediaAccounts, blockPosition: index }"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import gql from 'graphql-tag';
import AppCta, { MarketingBlocksAppCtaFragment } from '@/components/common/blocks/AppCta';
import FullWidthHeadingCta, {
  MarketingBlocksFullWidthHeadingCtaFragment,
} from '@/components/common/blocks/FullWidthHeadingCta';
import SmallTopicFeatureBars, { SmallTopicFeatureBarsFragment } from '@/components/common/blocks/SmallTopicFeatureBars';
import TeaserWithMultipleCtas, {
  MarketingBlocksTeaserWithMultipleCtasFragment,
} from '@/components/common/blocks/TeaserWithMultipleCtas';
import FullWidthTeaserBox, {
  MarketingBlocksFullWidthTeaserBoxFragment,
} from '@/components/common/blocks/FullWidthTeaserBox';
import ExerciseRoutineCardsSliderWithText, {
  MarketingBlocksRoutineCardsSliderWithTextFragment,
} from '@/components/common/blocks/ExerciseRoutineCardsSliderWithText';
import CenteredContent from '@/components/common/blocks/CenteredContent';
import { MarketingBlocksCenteredContentFragment } from '@/components/common/blocks/CenteredContent/index';
import TestimonialWithVariedBackground, {
  MarketingBlocksTestimonialWithVariedBackgroundFragment,
} from '@/components/common/blocks/TestimonialWithVariedBackground';
import MovingFullWidthImages, {
  MarketingBlocksMovingFullWidthImagesFragment,
} from '@/components/common/blocks/MovingFullWidthImages';
import CollectionsSlider, {
  MarketingBlocksCollectionsSliderFragment,
} from '@/components/common/blocks/CollectionsSlider/index';
import ProductSlider, { MarketingBlocksProductSliderFragment } from '@/components/common/blocks/ProductSlider';
import RoutineCardsSlider, {
  MarketingBlocksRoutineCardsSliderFragment,
} from '@/components/common/blocks/RoutineCardsSlider';
import SmallTopicFeatureBox, {
  MarketingBlocksSmallTopicFeatureBoxFragment,
} from '@/components/common/blocks/SmallTopicFeatureBox/index';
import ArticleRowSlide, { MarketingBlocksArticleRowSlideFragment } from '@/components/common/blocks/ArticleRowSlide';
import SmallStoryFeaturesWithBars, {
  MarketingBlocksSmallStoryFeaturesWithBars,
} from '@/components/common/blocks/SmallStoryFeaturesWithBars';
import FiftyFiftyArticleTeaser, {
  MarketingBlocksFiftyFiftyArticleTeaserFragment,
} from '@/components/common/blocks/FiftyFiftyArticleTeaser';
import LargeStoryFeatureWithBars, {
  MarketingBlocksLargeStoryFeatureWithBarsFragment,
} from '@/components/common/blocks/LargeStoryFeatureWithBars';
import ImageText, { MarketingBlocksImageTextFragment } from '~/components/common/blocks/ImageText';
import CopyText, { MarketingBlocksCopyTextFragment } from '@/components/common/blocks/CopyText';
import SocialFeature, { MarketingBlocksSocialFeatureFragment } from '@/components/common/blocks/SocialFeature';
import FormLink, { MarketingBlocksFormLinkFragment } from '@/components/common/blocks/FormLink';
import AsSeenIn, { MarketingBlocksAsSeenInFragment } from '@/components/common/blocks/AsSeenIn';
import SplitContentSlider, {
  MarketingBlocksSplitContentSliderFragment,
} from '@/components/common/blocks/SplitContentSlider';
import HeroProduct, { MarketingBlocksHeroProductFragment } from '@/components/common/blocks/HeroProduct';
import FormInModal, { MarketingBlocksFormInModalFragment } from '@/components/common/blocks/FormInModal';
import FixedBoxImages, { MarketingBlocksFixedBoxImagesFragment } from '@/components/common/blocks/FixedBoxImages';
import ImageOrVideo from '@/components/common/blocks/ImageOrVideo';
import { MarketingBlocksImageOrVideoFragment } from '@/components/common/blocks/ImageOrVideo/index';
import FeaturesRotation, { MarketingBlocksFeaturesRotationFragment } from '@/components/common/blocks/FeaturesRotation';
import Accordion, { MarketingBlocksAccordionFragment } from '@/components/common/blocks/Accordion';
import InContentCta, { MarketingBlocksInContentCtaFragment } from '@/components/common/blocks/InContentCta';
import InContentCtaBlocks, {
  MarketingBlocksInContentCtaBlocksFragment,
} from '@/components/common/blocks/InContentCtaBlocks';
import FullWidthAutoplayVideo, {
  MarketingBlocksFullWidthAutoplayVideoFragment,
} from '@/components/common/blocks/FullWidthAutoplayVideo';
import ParallaxImages, { MarketingBlocksParallaxImagesFragment } from '@/components/common/blocks/ParallaxImages';
import GenericIframe, { MarketingBlocksGenericIframeFragment } from '@/components/common/blocks/GenericIframe';
import NewsletterSignupForm, {
  MarketingBlocksNewsletterSignupFormFragment,
} from '@/components/common/blocks/NewsletterSignupForm';
import FeatureList, { MarketingBlocksFeatureListFragment } from '@/components/common/blocks/FeatureList';
import {
  CommonConversionArea as ConversionAreaOne,
  MarketingBlocksConversionAreaOneFragment,
} from '~/components/common/blocks/ConversionAreaOne';
import {
  CommonProductReviews as ProductReviews,
  MarketingBlocksProductReviewsFragment,
} from '~/components/common/blocks/ProductReviews';
import CourseConversionBanner, {
  MarketingBlocksCourseConversionBannerFragment,
} from '@/components/common/blocks/CourseConversionBanner';
import CrossSell, { MarketingBlocksCrossSellFragment } from '@/components/common/blocks/CrossSell';
import Upsell, { MarketingBlocksUpsellFragment } from '@/components/common/blocks/Upsell';

import guestComponent from '@/mixins/guestComponent';
import InsuranceReimbursement, {
  MarketingBlocksInsuranceReimbursementFragment,
} from '@/components/common/blocks/InsuranceReimbursement';
import CourseContent, { MarketingBlocksCourseContentFragment } from '~/components/common/blocks/CourseContent';
import FeaturesWithIcons, {
  ProductBlocksFeaturesWithIconsFragment,
} from '@/components/common/blocks/FeaturesWithIcons';

export const MarketingBlocksMatrixFragment = gql`
  fragment MarketingBlocksMatrixFragment on marketingBlocks_MatrixField {
    __typename
    ...MarketingBlocksAccordionFragment
    ...MarketingBlocksAppCtaFragment
    ...MarketingBlocksArticleRowSlideFragment
    ...MarketingBlocksAsSeenInFragment
    ...MarketingBlocksCenteredContentFragment
    ...MarketingBlocksCollectionsSliderFragment
    ...MarketingBlocksConversionAreaOneFragment
    ...MarketingBlocksCopyTextFragment
    ...MarketingBlocksCourseContentFragment
    ...MarketingBlocksCourseConversionBannerFragment
    ...MarketingBlocksCrossSellFragment
    ...MarketingBlocksFeatureListFragment
    ...MarketingBlocksFeaturesRotationFragment
    ...MarketingBlocksFiftyFiftyArticleTeaserFragment
    ...MarketingBlocksFixedBoxImagesFragment
    ...MarketingBlocksFormInModalFragment
    ...MarketingBlocksFormLinkFragment
    ...MarketingBlocksFullWidthAutoplayVideoFragment
    ...MarketingBlocksFullWidthHeadingCtaFragment
    ...MarketingBlocksFullWidthTeaserBoxFragment
    ...MarketingBlocksGenericIframeFragment
    ...MarketingBlocksHeroProductFragment
    ...MarketingBlocksImageOrVideoFragment
    ...MarketingBlocksImageTextFragment
    ...MarketingBlocksInContentCtaBlocksFragment
    ...MarketingBlocksInContentCtaFragment
    ...MarketingBlocksInsuranceReimbursementFragment
    ...MarketingBlocksLargeStoryFeatureWithBarsFragment
    ...MarketingBlocksMovingFullWidthImagesFragment
    ...MarketingBlocksNewsletterSignupFormFragment
    ...MarketingBlocksParallaxImagesFragment
    ...MarketingBlocksProductReviewsFragment
    ...MarketingBlocksProductSliderFragment
    ...MarketingBlocksRoutineCardsSliderFragment
    ...MarketingBlocksRoutineCardsSliderWithTextFragment
    ...MarketingBlocksSmallStoryFeaturesWithBars
    ...MarketingBlocksSmallTopicFeatureBoxFragment
    ...MarketingBlocksSocialFeatureFragment
    ...MarketingBlocksSplitContentSliderFragment
    ...MarketingBlocksTeaserWithMultipleCtasFragment
    ...MarketingBlocksTestimonialWithVariedBackgroundFragment
    ...MarketingBlocksUpsellFragment
    ...ProductBlocksFeaturesWithIconsFragment
    ...SmallTopicFeatureBarsFragment
  }
  ${MarketingBlocksAccordionFragment}
  ${MarketingBlocksAppCtaFragment}
  ${MarketingBlocksArticleRowSlideFragment}
  ${MarketingBlocksAsSeenInFragment}
  ${MarketingBlocksCenteredContentFragment}
  ${MarketingBlocksCollectionsSliderFragment}
  ${MarketingBlocksConversionAreaOneFragment}
  ${MarketingBlocksCopyTextFragment}
  ${MarketingBlocksCourseContentFragment}
  ${MarketingBlocksCourseConversionBannerFragment}
  ${MarketingBlocksCrossSellFragment}
  ${MarketingBlocksFeatureListFragment}
  ${MarketingBlocksFeaturesRotationFragment}
  ${MarketingBlocksFiftyFiftyArticleTeaserFragment}
  ${MarketingBlocksFixedBoxImagesFragment}
  ${MarketingBlocksFormInModalFragment}
  ${MarketingBlocksFormLinkFragment}
  ${MarketingBlocksFullWidthAutoplayVideoFragment}
  ${MarketingBlocksFullWidthHeadingCtaFragment}
  ${MarketingBlocksFullWidthTeaserBoxFragment}
  ${MarketingBlocksGenericIframeFragment}
  ${MarketingBlocksHeroProductFragment}
  ${MarketingBlocksImageOrVideoFragment}
  ${MarketingBlocksImageTextFragment}
  ${MarketingBlocksInContentCtaBlocksFragment}
  ${MarketingBlocksInContentCtaFragment}
  ${MarketingBlocksInsuranceReimbursementFragment}
  ${MarketingBlocksLargeStoryFeatureWithBarsFragment}
  ${MarketingBlocksMovingFullWidthImagesFragment}
  ${MarketingBlocksNewsletterSignupFormFragment}
  ${MarketingBlocksParallaxImagesFragment}
  ${MarketingBlocksProductReviewsFragment}
  ${MarketingBlocksProductSliderFragment}
  ${MarketingBlocksRoutineCardsSliderFragment}
  ${MarketingBlocksRoutineCardsSliderWithTextFragment}
  ${MarketingBlocksSmallStoryFeaturesWithBars}
  ${MarketingBlocksSmallTopicFeatureBoxFragment}
  ${MarketingBlocksSocialFeatureFragment}
  ${MarketingBlocksSplitContentSliderFragment}
  ${MarketingBlocksTeaserWithMultipleCtasFragment}
  ${MarketingBlocksTestimonialWithVariedBackgroundFragment}
  ${MarketingBlocksUpsellFragment}
  ${ProductBlocksFeaturesWithIconsFragment}
  ${SmallTopicFeatureBarsFragment}
`;

export default {
  name: 'MarketingBlocks',
  components: {
    Accordion,
    AppCta,
    ArticleRowSlide,
    AsSeenIn,
    CenteredContent,
    CollectionsSlider,
    ConversionAreaOne,
    CopyText,
    CourseContent,
    CourseConversionBanner,
    CrossSell,
    ExerciseRoutineCardsSliderWithText,
    FeatureList,
    FeaturesRotation,
    FeaturesWithIcons,
    FiftyFiftyArticleTeaser,
    FixedBoxImages,
    FormInModal,
    FormLink,
    FullWidthAutoplayVideo,
    FullWidthHeadingCta,
    FullWidthTeaserBox,
    GenericIframe,
    HeroProduct,
    ImageOrVideo,
    ImageText,
    InContentCta,
    InContentCtaBlocks,
    InsuranceReimbursement,
    LargeStoryFeatureWithBars,
    MovingFullWidthImages,
    NewsletterSignupForm,
    ParallaxImages,
    ProductReviews,
    ProductSlider,
    RoutineCardsSlider,
    SmallStoryFeaturesWithBars,
    SmallTopicFeatureBars,
    SmallTopicFeatureBox,
    SocialFeature,
    SplitContentSlider,
    TeaserWithMultipleCtas,
    TestimonialWithVariedBackground,
    Upsell,
    WallsIoFeed: GenericIframe,
  },
  mixins: [guestComponent],
  props: {
    blocks: {
      type: Array,
      default: () => [],
    },
    socialMediaAccounts: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
