import gql from 'graphql-tag';
import { MarketingBannerFragment, BenefitsPdpFragment } from '~/store/globalBanner.gql';
import { PaymentMethodsFragment } from '@/components/layout/footer/PaymentMethods';
import { CartNotificationFragment } from '@/components/cart/CartNotification';
import {
  CartShippingEntryFragment,
  CartDealsSettingsFragment,
  DeliveryDetailsSettingsFragment,
  HighDemandProductsSettingsFragment,
} from '@/store/cartSettings';
import { MainNavTopLevelEntryFragment, HelpNavFragment } from '@/store/mainNav';
import { AccountBenefitsFragment } from '@/components/layout/header/MiniNav';
import { AccountNotificationFragment } from '@/components/account/AccountNotifications';
import { AccountRoutinesFragment } from '@/components/account/AccountRoutineSlider';
import { AccountProductsFragment } from '@/components/account/AccountProductSlider';
import CartProgressQuery from '@/components/cart/CartProgress/CartProgress.gql';

const AppInitQuery = gql`
  query AppInitQuery($site: String!) {
    navTops: entries(section: "mainNav", site: [$site]) {
      ...MainNavTopLevelEntryFragment
    }
    globalBanner: globalSet(site: [$site], handle: "globalBanner") {
      ...MarketingBannerFragment
      ...BenefitsPdpFragment
    }
    paymentMethods: globalSet(site: [$site], handle: "paymentMethods") {
      ...PaymentMethodsFragment
    }
    cartSettings: globalSet(site: [$site], handle: "cartSettings") {
      ...CartNotificationFragment
      ...CartShippingEntryFragment
      ...CartDealsSettingsFragment
      ...HighDemandProductsSettingsFragment
      ...DeliveryDetailsSettingsFragment
    }
    helpNav: globalSet(site: [$site], handle: "helpNav") {
      ...HelpNavFragment
    }
    account: globalSet(site: [$site], handle: "account") {
      ...AccountBenefitsFragment
      ...AccountNotificationFragment
      ...AccountRoutinesFragment
      ...AccountProductsFragment
      ... on account_GlobalSet {
        isAuthDisabled
        maintenanceMessage
      }
    }
    pickystory: globalSet(site: [$site], handle: "pickystory") {
      ... on pickystory_GlobalSet {
        enableCartXSellTeasers
        enableUpsellTeasers
        enableXSellTeasers
      }
    }
    contactWidget: globalSet(site: [$site], handle: "contactWidget") {
      ... on contactWidget_GlobalSet {
        heading
        teaser
        externalUrl
        globallyEnabled
        icon {
          kind
          url
          width
          height
        }
      }
    }
  }
  ${AccountBenefitsFragment}
  ${AccountNotificationFragment}
  ${AccountProductsFragment}
  ${AccountRoutinesFragment}
  ${CartDealsSettingsFragment}
  ${CartNotificationFragment}
  ${CartShippingEntryFragment}
  ${DeliveryDetailsSettingsFragment}
  ${HelpNavFragment}
  ${HighDemandProductsSettingsFragment}
  ${MainNavTopLevelEntryFragment}
  ${PaymentMethodsFragment}
  ${MarketingBannerFragment}
  ${BenefitsPdpFragment}
`;

export const state = () => ({
  showUpsellTeasers: false,
  showXSellTeasers: false,
  showCartXSellTeasers: false,
});

export const mutations = {
  INIT(state, { enableUpsellTeasers, enableXSellTeasers, enableCartXSellTeasers }) {
    state.showUpsellTeasers = enableUpsellTeasers;
    state.showXSellTeasers = enableXSellTeasers;
    state.showCartXSellTeasers = enableCartXSellTeasers;
  },
};

export const actions = {
  /**
   * initializes app shell on ssr page load
   */
  async initShell({ commit }) {
    try {
      const { navTops, helpNav, globalBanner, paymentMethods, cartSettings, account, pickystory, contactWidget } =
        await this.$craftRequest(AppInitQuery, {
          site: this.$i18n.locale,
        });

      commit('mainNav/INIT', navTops, { root: true });
      commit('mainNav/SET_HELP_NAV', helpNav, { root: true });
      commit('mainNav/SET_BENEFITS_NAV', account.benefitsBlocks, { root: true });
      commit('mainNav/SET_CONTACT_WIDGET', contactWidget, { root: true });
      commit('globalBanner/INIT', globalBanner, { root: true });
      commit('cartSettings/INIT', { ...cartSettings, paymentMethods }, { root: true });
      commit('cartSettings/INIT_CART_DEALS', cartSettings, { root: true });
      commit('account/INIT', account, { root: true });
      commit('app/INIT', pickystory, { root: true });

      const response = await this.$craftRequest(CartProgressQuery, {
        site: this.$i18n.locale,
      });

      commit('cart/SET_FREE_SHIPPING_THRESHOLD', response.globalSet.freeShippingThreshold ?? 0, { root: true });
    } catch (e) {
      console.error('Error initializing app shell', e);
      this.$sentry.captureException(e);
    }
  },
};
