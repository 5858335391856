import Vue from 'vue';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

export default function (data = {}, schema = null) {
  const defaults = cloneDeep(data);

  const form = Vue.observable({
    ...data,
    isDirty: false,
    errors: {},
    hasErrors: false,
    processing: false,
    wasSuccessful: false,

    data() {
      return Object.keys(data).reduce((carry, key) => {
        carry[key] = this[key];
        return carry;
      }, {});
    },

    async validate(options = {}) {
      if (!schema) {
        console.warn('no validation schema provided');
        return Promise.resolve();
      }

      this.hasErrors = false;

      try {
        const result = await schema.validate(this.data(), { abortEarly: false, ...options });

        return Promise.resolve(result);
      } catch (error) {
        this.hasErrors = true;

        if (error.inner) {
          this.errors = error.inner.reduce((carry, error) => {
            if (carry[error.path]) {
              return carry;
            }

            carry[error.path] = error.message;
            return carry;
          }, {});
        }

        return Promise.reject(error.inner);
      }
    },

    reset(...fields) {
      const clonedDefaults = cloneDeep(defaults);
      if (fields.length === 0) {
        Object.assign(this, clonedDefaults);
      } else {
        Object.assign(
          this,
          Object.keys(clonedDefaults)
            .filter((key) => fields.includes(key))
            .reduce((carry, key) => {
              carry[key] = clonedDefaults[key];
              return carry;
            }, {})
        );
      }

      return this;
    },

    async submit(requestPromise) {
      this.clearErrors();
      this.processing = true;
      this.wasSuccessful = false;

      try {
        await requestPromise;
        this.wasSuccessful = true;
        this.processing = false;
      } catch (error) {
        this.processing = false;

        try {
          this.errors = error.data ? error.data : error.response.data;
        } catch (e) {
          console.log({ e });
        }

        this.hasErrors = true;
      }

      return Promise.resolve();
    },

    clearErrors(...fields) {
      this.errors = Object.keys(this.errors).reduce(
        (carry, field) => ({
          ...carry,
          ...(fields.length > 0 && !fields.includes(field) ? { [field]: this.errors[field] } : {}),
        }),
        {}
      );

      this.hasErrors = Object.keys(this.errors).length > 0;

      return this;
    },
  });

  // eslint-disable-next-line no-new
  new Vue({
    created() {
      this.$watch(
        () => form,
        (newValue) => {
          form.isDirty = !isEqual(form.data(), defaults);
        },
        { immediate: true, deep: true }
      );
    },
  });

  return form;
}
