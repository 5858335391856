<template>
  <div class="overflow-y-scroll h-full">
    <div v-if="cart && cart.id">
      <template v-if="lineItems.length > 0">
        <CartAvailabilityHint />
        <CartProgress class="p-4 lg:px-6" />
        <div class="gap-y-6 p-4 lg:px-6">
          <LineItem v-for="item in lineItems" :key="item.id" :line-item="item" class="mb-6" />
          <CartDeals v-if="$store.state.app.showCartXSellTeasers" />
          <CartSummary />
        </div>
        <div class="p-4 lg:px-6 sticky bottom-0">
          <CartCheckoutButton />
        </div>
      </template>
      <CartEmpty v-else />
    </div>
  </div>
</template>

<script>
import LineItem from '@/components/cart/LineItem';
import { mapActions, mapGetters, mapState } from 'vuex';
import CartCheckoutButton from '@/components/cart/CartCheckoutButton';
import CartEmpty from '@/components/cart/CartEmpty';
import CartSummary from '@/components/cart/CartSummary';
import CartDeals from '@/components/cart/CartDeals/CartDeals';
import CartProgress from '@/components/cart/CartProgress/index';
import CartAvailabilityHint from '@/components/cart/CartAvailabilityHint';

export default {
  components: {
    CartAvailabilityHint,
    CartCheckoutButton,
    CartDeals,
    CartEmpty,
    CartProgress,
    CartSummary,
    LineItem,
  },
  computed: {
    ...mapGetters('cart', ['lineItems']),
    ...mapState('cart', ['cart']),
    ...mapState('cartSettings', ['shippingEntry']),
  },
  methods: {
    ...mapActions('cart', ['close']),
  },
};
</script>
