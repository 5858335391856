<template>
  <button
    type="submit"
    class="btn-pill-white relative"
    :class="[
      { 'pointer-events-auto opacity-75': isLoading },
      { 'pointer-events-none opacity-50': disabled },
      verticalMargin,
    ]"
  >
    <span :class="{ invisible: isLoading }">
      <slot />
    </span>
    <div v-if="isLoading" class="absolute inset-0 flex items-center justify-center">
      <SpinnerIcon class="animate-spin w-6 h-6" />
    </div>
  </button>
</template>

<script>
import SpinnerIcon from '@/components/icons/SpinnerIcon';

export default {
  name: 'FormButton',

  components: { SpinnerIcon },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    verticalMargin: {
      type: String,
      default: 'my-6',
    },
  },
};
</script>
