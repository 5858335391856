export const state = () => ({
  uspBannerEnabled: false,
  uspBlocks: [],
  benefitsEnabled: false,
  benefitBlocks: [],
  closableBannerEnabled: false,
  closableBlocks: [],
});

export const mutations = {
  INIT(state, { marketingBannerEnabled, uspBarBlocks, benefitsEnabled, benefitBlocks, showUsps, marketingUspBlocks }) {
    state.marketingBannerEnabled = marketingBannerEnabled;
    state.showUsps = showUsps;
    state.marketingUspBlocks = marketingUspBlocks;
    state.uspBarBlocks = uspBarBlocks;
    state.benefitsEnabled = benefitsEnabled;
    state.benefitBlocks = benefitBlocks;
  },
};

export const getters = {
  showBenefitsBanner(state) {
    return state.benefitsEnabled && state.benefitBlocks.length > 0;
  },
};
