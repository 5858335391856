<template>
  <div class="conversion-area md:grid-cols-2 container grid grid-cols-1 py-12" :class="{ 'items-center': isAdjusted }">
    <div v-if="product">
      <ProductImageGallery
        v-if="showImageGallery"
        :items="imageListWithSrcSet"
        :image-index="state.currentSlide"
        @closeModal="toggleModal()"
      />

      <figure class="relative">
        <CardSlider
          v-if="productImages.length"
          ref="conversionAreaSlider"
          class="lg:overflow-x-hidden"
          :slide-gap="0"
          :show-arrows="false"
          :fullwidth-slides="true"
          :breakpoints="breakpoints"
          :is-container="false"
          :no-padding="isAdjusted"
          @change="setActiveSlide"
        >
          <div
            v-for="(image, index) in productImages"
            :key="image ? image.id + index : index"
            class="slide cursor-pointer"
            data-test-id="openModal"
            @click="toggleModal(index)"
          >
            <ProductDetailVariantImage
              v-if="image"
              :variant="relatedVariant(image.id)"
              :product-is-new="isNew"
              :is-adjusted="isAdjusted"
            >
              <FixedAspect :ratio="isAdjusted ? 3 / 4 : 2 / 3">
                <CldnImg
                  v-if="image"
                  :src="image.src"
                  :alt="image.altText"
                  :ratio="isAdjusted ? 3 / 4 : 2 / 3"
                  crop="fit"
                  :loading="index === 0 ? 'eager' : 'lazy'"
                  sizes="(min-width: 1440px) 648px, (min-width: 1024px) 50vw, 100vw"
                  :class="{
                    'md:!max-w-[45vw] xl:!max-w-[648px] md:max-h-[320px] lg:max-h-[400px] xl:max-h-[450px]':
                      !isAdjusted,
                  }"
                />
              </FixedAspect>
            </ProductDetailVariantImage>
          </div>
        </CardSlider>

        <div data-test-id="slider-arrows" class="top-1/2 inset-x-0 absolute z-20">
          <div
            v-if="productImages && productImages.length > 1"
            class="absolute -translate-y-1/2"
            :class="[isAdjusted ? 'bg-gray-100/75 rounded-full left-5 p-0 w-10 h-10' : 'w-8 py-3 md:px-4']"
          >
            <ArrowButton
              direction="left"
              :arrow-disabled="state.isFirst"
              :class="{ 'pt-[10px] pl-[8px]': isAdjusted }"
              @click="prevSlide"
            />
          </div>
          <div
            v-if="productImages && productImages.length > 1"
            class="absolute -translate-y-1/2"
            :class="[isAdjusted ? 'bg-gray-100/75 rounded-full right-5 p-0 w-10 h-10' : 'w-8 right-0 py-3']"
          >
            <ArrowButton
              direction="right"
              :arrow-disabled="state.isLast"
              :class="{ 'pt-[10px] pl-[8px]': isAdjusted }"
              @click="nextSlide"
            />
          </div>
        </div>

        <div
          class="fluid-xxs uppercase tracking-wide font-medium h-7 md:justify-start box-border top-0 absolute z-10 flex justify-center leading-3"
          :class="{ 'top-5 left-5': isAdjusted }"
          data-test-id="cardHeader"
        >
          <span v-if="cardHeader" class="h-7 items-center px-4 py-2" :style="cardHeader.style">
            {{ cardHeader.text }}
          </span>
        </div>
      </figure>
    </div>

    <div class="md:p-8 md:mt-0 mt-12">
      <div class="flex flex-col items-start">
        <div class="flex items-center self-start">
          <ProductTypeBadge
            v-if="product.productType && !isAdjusted"
            :product-type="product.productType"
            text-mobile
            inline-with-name
            border-mobile
            padding-mobile
          />

          <ClimateNeutralBadge v-if="climateNeutral" :is-epp="climateEpp" :is-band="climateBand" class="ml-3" />
        </div>

        <h1 v-if="product.title" :class="[isAdjusted ? 'fluid-2xl leading-none' : 'uppercase my-4']">
          {{ product.title }}
        </h1>

        <p v-if="product.subtitle && product.subtitle !== 'null'" class="max-w-md mb-4">
          {{ product.subtitle }}
        </p>

        <RatingStars
          v-if="reviews"
          :rating-value="totalScore"
          :rating-volume="totalCount"
          :use-scroll="true"
          class="my-4"
        />
      </div>

      <ProductPriceLine
        v-if="variants.length"
        class="my-4"
        :price="selectedVariant.price"
        :compare-price="selectedVariant.compareAtPrice"
        :unit-price="selectedVariant.unitPrice"
        :unit-price-measurement="selectedVariant.unitPriceMeasurement"
        :show-quantity="variants.length === 1"
      />

      <PaypalExpressHint v-if="hasPaypalMethod && selectedVariant" :amount="selectedVariant.price.amount" />

      <template v-if="!isAdjusted">
        <ProductOptions
          v-for="(option, index) in product.options"
          :key="'opt_' + index"
          v-bind="{
            option,
            selectedVariant,
            variants,
            swatches: product.optionSwatches,
            maxSwatches: 12,
            alwaysShow: product.options.length >= 2,
          }"
          ref="productOptions"
          swatch-img-class="md:h-8 md:w-8 w-6 h-6"
          class="self-center my-4"
          data-test-id="colorOptions"
          @clickOption="setOption"
        />
      </template>

      <CtaButton
        v-if="availableForSale"
        data-testid="addToCart"
        :loading="isAddingToCart"
        @click.native="onClickAddToCart"
      >
        {{ isCourse ? $t('cart.bookNow') : $t('cart.addToCart') }}
      </CtaButton>

      <button v-else-if="!availableForSale" class="btn-pill-gray inline-block pointer-events-none">
        {{ $t('product.comingSoon') }}
      </button>

      <client-only>
        <!-- prevent Hydrationerror on SSR racecondition on providing store -->
        <div v-if="shippingEntryUri && !isAddOn && !isCourse" class="fluid-xs leading-none mt-6">
          <i18n path="cart.shippingCost" tag="p" class="text-copy">
            <template #link>
              <nuxt-link :to="$localeRootPath(shippingEntryUri)">{{ $t('cart.link') }}</nuxt-link>
            </template>
          </i18n>
        </div>
      </client-only>

      <SmallAddOnTeaser v-if="addOn && !isAdjusted" class="mt-6 max-w-md" :heading="promoHeading" :image="promoImage" />

      <div v-if="!isCourse && !isAddOn && benefitBlocks && benefitBlocks.length > 0">
        <BenefitSection class="mt-4" :benefits="benefitBlocks" :show-made-in-germany="product.isMadeInGermany" />
      </div>
    </div>

    <PreAssessmentModal
      v-if="hasPreAssessment && showPreAssessmentModal"
      :showing="showPreAssessmentModal"
      :info="agreementCategory[0]"
      @buy="addToCart"
      @close="showPreAssessmentModal = false"
    />
  </div>
</template>

<script>
import { addVariantToCart } from '@/utils/product';
import { buildEECProduct } from '@/utils/tracking';
import ProductImageGallery, { imageList } from '@/components/shop/products/ProductImageGallery';
import { mapGetters, mapState } from 'vuex';

import addons from '@/mixins/addons';
import tsMixin from '@/mixins/trustedShops';

import ArrowButton from '@/components/common/ArrowButton';
import BenefitSection from '@/components/shop/BenefitSection/BenefitSection.vue';
import CardSlider from '@/components/common/CardSlider';
import CldnImg from '@/components/CldnImg';
import ClimateNeutralBadge from '@/components/shop/products/ClimateNeutralBadge';
import CtaButton from '@/components/common/CtaButton';
import FixedAspect from '@/components/common/FixedAspect';
import PaypalExpressHint from '@/components/PaypalExpressHint.vue';
import PreAssessmentModal from '~/components/common/blocks/PreAssessmentModal';
import ProductDetailVariantImage from '@/components/shop/products/ProductDetailVariantImage';
import ProductOptions from '@/components/shop/products/ProductOptions';
import ProductPriceLine from '~/components/shop/products/ProductPriceLine';
import ProductTypeBadge from '@/components/shop/products/ProductTypeBadge';
import RatingStars from '@/components/shop/products/RatingStars';
import SmallAddOnTeaser from '@/components/common/SmallAddOnTeaser';

export default {
  components: {
    ArrowButton,
    BenefitSection,
    CardSlider,
    CldnImg,
    ClimateNeutralBadge,
    CtaButton,
    FixedAspect,
    ProductImageGallery,
    PaypalExpressHint,
    PreAssessmentModal,
    ProductDetailVariantImage,
    ProductOptions,
    ProductPriceLine,
    ProductTypeBadge,
    RatingStars,
    SmallAddOnTeaser,
  },

  mixins: [tsMixin, addons],

  props: {
    product: {
      type: Object,
      default: () => null,
    },
    tsProduct: {
      type: Object,
      default: () => null,
    },
    addOn: {
      type: Object,
      default: () => null,
    },
    agreementCategory: {
      type: Array,
      default: () => null,
    },
    isAdjusted: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showPreAssessmentModal: false,
      state: {
        currentSlide: 0,
        isFirst: false,
        isLast: false,
      },
      breakpoints: [
        {
          minWindowWidth: 0,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      ],
      selectedVariant: null,
      showImageGallery: false,
      isAddingToCart: false,
    };
  },

  computed: {
    ...mapGetters('cartSettings', ['shippingEntryUri', 'hasPaypalMethod']),
    ...mapGetters('cart', ['lineItems']),
    ...mapState('globalBanner', ['benefitBlocks']),

    reviews() {
      return this.tsProduct?.reviews;
    },

    variants() {
      return this.product?.variants?.edges?.map((e) => e.node) || [];
    },

    productImages() {
      return this.product?.images?.edges?.map((el) => el.node).filter(Boolean) || [];
    },

    imageListWithSrcSet() {
      return imageList(this.$cldn.imgUrl.bind(this.$cldn), this.productImages).map((img, index) => ({
        ...img,
        id: this.productImages[index].id,
      }));
    },

    cardHeader() {
      if (this.product?.cardHeader?.active) {
        const header = this.product.cardHeader;
        return { text: header.text, style: { color: header.textColor, backgroundColor: header.backgroundColor } };
      }
      return null;
    },

    productDescription() {
      return this.product?.descriptionHtml?.replace(/<[^>]*>?/gm, '');
    },

    isNew() {
      return this.product && this.product.flags?.new;
    },

    fullProductName() {
      return this.product?.productType + ' ' + this.product?.title + ' ' + this.selectedVariant?.title;
    },

    jsonData() {
      return {
        variant: this.selectedVariant,
        rating: this.tsProduct && {
          ratingValue: Math.round(this.totalScore * 10) / 10,
          ratingCount: this.totalCount,
          reviewCount: this.reviewsCount,
          worstRating: this.worstScore,
          bestRating: this.bestScore,
        },
        product: {
          name: this.fullProductName,
          image: this.productImages?.length && this.productImages[0]?.src,
          description: this.productDescription,
          url: this.$route?.fullPath,
        },
        reviews: this.tsProduct && this.recentTopReviews,
      };
    },

    availableForSale() {
      return this.product.availableForSale && this.selectedVariant?.available;
    },

    promoHeading() {
      return this.product?.digitalAddOn?.promoHeading;
    },

    promoImage() {
      return this.convertImage(this.product?.digitalAddOn?.digitalAddOnMainImageFullPath);
    },

    climateNeutral() {
      return (
        this.product?.badges?.findIndex((badge) => {
          return badge.text === 'CLIMATE_NEUTRAL_BADGE';
        }) > -1 ||
        this.climateEpp ||
        this.climateBand
      );
    },

    climateEpp() {
      return (
        this.product?.badges?.findIndex((badge) => {
          return badge.text === 'Badge Climate EPP';
        }) > -1
      );
    },

    climateBand() {
      return (
        this.product?.badges?.findIndex((badge) => {
          return badge.text === 'Badge Climate Bands';
        }) > -1
      );
    },

    hasPreAssessment() {
      return this.agreementCategory?.length > 0;
    },

    isCourse() {
      try {
        const value = JSON.parse(this.product?._digitalProductType?.value);

        return value?.digitalProductType === 'Online Course';
      } catch (e) {
        return false;
      }
    },

    isAddOn() {
      try {
        const value = JSON.parse(this.product?._digitalProductType?.value);

        return value?.digitalProductType === 'Digital Training';
      } catch (e) {
        return false;
      }
    },
  },

  watch: {
    selectedVariant(newValue, oldValue) {
      this.changeSlide(newValue.image?.id);
      this.trackSelectedVariant();
    },
  },

  created() {
    const selectedSku = this.$route.query.sku;

    if (selectedSku) {
      this.selectedVariant = this.variants.find((variant) => {
        return variant.sku?.toLowerCase() === selectedSku.toLowerCase();
      });
      if (this.selectedVariant) {
        return this.changeSlide(this.selectedVariant.image.id);
      }
    }

    this.selectedVariant = this.variants[0];

    // After a variant is selected, update the schema
    if (this.$schema) {
      this.$schema.setSchema(this.jsonData);
    }
  },

  methods: {
    setOption(newVariant) {
      this.selectedVariant = newVariant;
    },

    relatedVariant(imageId) {
      return (
        this.variants.find((variant) => {
          return variant.image.id === imageId;
        }) || this.variants[0]
      );
    },

    trackSelectedVariant() {
      if (!this.selectedVariant) return;

      this.$_gtm.push({
        event: 'EECproductDetailView',
        ecommerce: {
          currencyCode: this.selectedVariant.price.currencyCode,
          detail: {
            actionField: { list: null },
            products: [buildEECProduct(this.selectedVariant, this.product, 1, this.rating?.value)],
          },
        },
      });
    },

    toggleModal() {
      this.showImageGallery = !this.showImageGallery;
    },

    changeSlide(imageId) {
      const position = this.productImages.findIndex((image) => {
        if (image) {
          return image.id === imageId;
        } else {
          return false;
        }
      });
      if (position >= 0) {
        this.changeSliderIndex(position);
      }
    },

    changeSliderIndex(index) {
      if (!this.$refs.conversionAreaSlider) return;

      this.$refs.conversionAreaSlider.$emit('scrollToSlide', index);
    },

    setActiveSlide(state) {
      if (typeof state === 'object' && state) {
        this.state = state;
      }
    },

    prevSlide() {
      const newIndex = Math.max(0, this.state.currentSlide - 1);
      const image = this.productImages[newIndex];

      if (this.$refs.productOptions && image) {
        this.$refs.productOptions[0].selectByImage(image);
      }

      this.$refs.conversionAreaSlider.$emit('prev');
    },

    nextSlide() {
      const newIndex = Math.min(this.productImages.length - 1, this.state.currentSlide + 1);
      const image = this.productImages[newIndex];

      if (this.$refs.productOptions && image) {
        this.$refs.productOptions[0].selectByImage(image);
      }

      this.$refs.conversionAreaSlider.$emit('next');
    },

    async onClickAddToCart() {
      if (this.hasPreAssessment) {
        this.showPreAssessmentModal = true;
      } else {
        this.isAddingToCart = true;

        try {
          await this.addToCart();
        } catch (e) {
          this.$sentry.captureException(e);
        } finally {
          this.isAddingToCart = false;
        }
      }
    },

    addToCart() {
      return addVariantToCart.call(
        this,
        this.product,
        this.selectedVariant,
        this.isCourse,
        this.rating?.value,
        this.addOn
      );
    },
  },
};
</script>
