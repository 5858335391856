<template>
  <div v-click-outside="hide" class="relative">
    <button @click="isOpen = !isOpen">
      <slot name="button"></slot>
    </button>

    <div
      v-show="isOpen"
      class="absolute bg-white px-4 py-3 rounded shadow-lg max-w-xs left-1/4 -translate-x-1/4 bottom-full -translate-y-3 after:border-x-8 after:border-x-transparent after:border-t-8 after:border-t-white after:absolute after:-bottom-2 after:left-1/4 after:-translate-x-1/4"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  directives: {
    ClickOutside,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    hide() {
      if (!this.isOpen) return;

      this.isOpen = false;
    },
  },
};
</script>
