<template>
  <div class="flex flex-col w-full">
    <label v-if="showLabel && label" :for="id" class="fluid-sm leading-tightest mb-2 font-light text-black"
      >{{ label }}<span v-if="required" class="text-br-red">*</span></label
    >
    <div
      class="select-wrapper items-center h-9 relative w-full"
      :class="[disabled ? 'after:bg-transparent' : 'after:bg-black']"
    >
      <select
        :id="id"
        class="fluid-sm leading-tightest focus:outline-none w-full h-full font-medium grid text-black border-b appearance-none px-6"
        :class="[
          { 'text-center': textCenter },

          !error ? 'border-gray-200 focus:border-br-green' : 'border-br-red',
          {
            'bg-white': appearance === 'light',
            'bg-lighter-gray': appearance === 'default',
          },
        ]"
        v-bind="$attrs"
        :aria-invalid="error ? true : null"
        :aria-describedby="error ? `${id}-error` : null"
        :disabled="disabled"
        @input="
          (e) => {
            $emit('input', e.target.value);
          }
        "
      >
        <option v-if="showLabel" value="" :selected="!value" disabled>{{ $t(label) }}</option>
        <option
          v-for="(option, index) in options"
          :key="`${index}-${id}-${option.value}`"
          class="font-light"
          :value="option.value"
          :selected="option.value === value"
          :disabled="option.disabled"
        >
          {{ option.label }}
        </option>
      </select>
      <div class="right-9 absolute top-0 flex flex-row items-center h-full">
        <WarningIcon v-if="error" class="h-4" />
      </div>
    </div>
    <div class="fluid-xs leading-tightest text-medium py-1">
      <p v-if="helpText">{{ helpText }}</p>
      <p v-if="showErrors && error" :id="`${id}-error`" class="text-br-red">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import WarningIcon from '@/components/icons/WarningIcon';

export default {
  name: 'FormSelectInput',

  components: { WarningIcon },

  props: {
    label: {
      type: String,
      default: null,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    showErrors: {
      type: Boolean,
      default: false,
    },
    textCenter: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    appearance: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'light'].includes(value),
    },

    helpText: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      id: this._uid,
    };
  },
};
</script>

<style scoped>
.select-wrapper::after {
  content: '';
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  position: absolute;
  right: 10px;
  top: 14px;
  width: 12px;
  height: 7px;
  pointer-events: none;
}
select,
select:after {
  grid-area: select;
}
</style>
