<template>
  <div class="relative" data-testid="breadcrumb">
    <div class="absolute left-0 w-12 h-full" />
    <div
      class="sm:container whitespace-nowrap bread-crumb flex items-center h-8 overflow-x-auto fluid-xs leading-tightest font-medium"
    >
      <nuxt-link :to="$localeRootPath('/')" class="sm:ml-0 ml-8" data-testid="breadcrumb-home"> Home </nuxt-link>
      <div v-for="({ label, route }, i) in breadCrumb" :key="i" class="last:pr-8 sm:last:pr-0">
        <TriangleIcon class="spacer ml-2 h-1" />
        <nuxt-link :to="route" class="ml-1" data-testid="breadcrumb-child">
          {{ label }}
        </nuxt-link>
      </div>
    </div>
    <div class="absolute top-0 right-0 w-12 h-full" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import TriangleIcon from '@/components/icons/TriangleIcon'
export default {
  components: {
    TriangleIcon
  },
  props: {
    crumbs: {
      type: Array,
      default: () => []
    }
  },
  computed: mapState('page', ['breadCrumb'])
}
</script>

<style scoped>
svg {
  @apply inline-block fill-current stroke-current stroke-0;
}
.bread-crumb {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}
.bread-crumb::-webkit-scrollbar {
  @apply hidden;
}
.bread-crumb-fadeout-to-left {
  background: linear-gradient(to left, rgb(213, 224, 202) 30%, rgba(213, 224, 202, 0) 70%);
}
.bread-crumb-fadeout-to-right {
  background: linear-gradient(to right, rgb(213, 224, 202) 30%, rgba(213, 224, 202, 0) 70%);
}
</style>
