// store to hold return form submission data

export const state = () => ({
  hasSubmission: false,
  success: false,
  label: null,
});

export const getters = {
  hasLabel(state) {
    return state.hasSubmission && state.label && state.label.length > 0;
  },

  downloadString(state) {
    const label = state.label && state.label[0];

    if (!label) {
      return null;
    }

    return `data:application/pdf;base64,${label}`;
  },
};

export const mutations = {
  CLEAR_SUBMISSION(state) {
    state.hasSubmission = false;
    state.success = false;
    state.label = null;
  },

  SET_SUBMISSION_RESPONSE(state, response) {
    state.label = response.label;
    state.hasSubmission = true;
    state.success = response.success;
  },
};
